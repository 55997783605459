$row-height: 48px;
$header-height: 48px;
$font-size: 14px;
$secondary-font-size: 14px;
//$cell-horizontal-padding: 20px;

@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark.scss";

.ag-body-viewport,
.ag-row {
  background-color: #293742 !important;
}

.ag-row {
  border: 0 solid transparent !important;
}

.ag-row:hover,
.ag-header,
.ag-menu {
  background-color: #30404d !important;
}

.ag-filter-apply-panel > button {
  background-color: #252b2e;
}
